import React from 'react'
import DayPicker, { DayModifiers } from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import './spots.css'
import { Spot } from 'apiConfig/apiMethods'

interface Props {
  spots: Spot[]
  selectedDay: (day: Date) => void
  updatedMonth: (year: number, month: number) => void
  type: string
  year: number
  month: number
}

export const isDayDisabled = (
  currentTime: Date,
  dateToCheck: Date,
  availableSpots: string[],
  allSpots: Spot[],
) => {
  const today = new Date(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate())

  // Check if there are spots still available today
  if (availableSpots.includes(today.toString()) && today.toString() === dateToCheck.toString()) {
    const spotsToday = allSpots.filter((spot) => {
      let spotDate = new Date(spot.startDate)

      spotDate.setHours(0, 0, 0)

      return spotDate.toString() === today.toString()
    })

    const spotsAvailableToday = spotsToday.filter((spot) => {
      return new Date(spot.startDate).getTime() > currentTime.getTime()
    })

    if (today.toString() === dateToCheck.toString()) {
      return (
        today > dateToCheck ||
        !availableSpots.includes(dateToCheck.toString()) ||
        spotsAvailableToday.length === 0
      )
    }
  }

  return today > dateToCheck || !availableSpots.includes(dateToCheck.toString())
}

class Spots extends React.Component<Props> {
  state = {
    availableSpots: [] as string[],
  }

  componentDidMount() {
    this.setAvailableSpots()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps === this.props) {
      return
    }

    if (prevProps.spots !== this.props.spots) {
      this.setAvailableSpots()
    }
  }

  setAvailableSpots = () => {
    const results = this.props.spots.map(async (obj) => {
      let date = new Date(obj.startDate)
      date.setHours(0, 0, 0)
      return date.toString()
    })
    Promise.all(results).then((completed) => this.setState({ availableSpots: completed }))
  }

  onMonthChange = (month: Date) => {
    this.props.updatedMonth(month.getFullYear(), month.getMonth())
  }

  render() {
    const { spots } = this.props

    const getDaysInMonth = (month: number, year: number) => {
      // Since no month has fewer than 28 days
      let date = new Date(year, month, 1)
      const days: Date[] = []
      while (date.getMonth() === month) {
        days.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }
      return days
    }

    const daysInMonth = getDaysInMonth(this.props.month, this.props.year)

    const handleDayClick = (date: Date, { disabled }: DayModifiers) => {
      if (disabled) {
        return
      }
      this.props.selectedDay(date)
    }

    // DayPicker config

    const WEEKDAYS_SHORT = ['S', 'M', 'T', 'O', 'T', 'F', 'L']

    const MONTHS = [
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ]

    return (
      <div>
        <DayPicker
          months={MONTHS}
          onMonthChange={(month) => this.onMonthChange(month)}
          weekdaysShort={WEEKDAYS_SHORT}
          firstDayOfWeek={1}
          initialMonth={new Date(this.props.year, this.props.month)}
          onDayClick={(day, mod) => handleDayClick(day, mod)}
          disabledDays={daysInMonth.filter((date) =>
            isDayDisabled(new Date(), date, this.state.availableSpots, this.props.spots),
          )}
        />
      </div>
    )
  }
}

export default Spots
