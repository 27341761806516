import React from 'react'
import './mypages.less'
import { getPersonalData, getMembershipData } from 'apiConfig/apiMethods'
import LoaderEllipsis from 'components/loader/LoaderEllipsis'
import Authorize from './Authorize'

interface CardData {
  name: string
  industry: 'Kultursektorn' | 'Kommunikation'
}

interface State {
  cardData?: CardData
}

class MittKort extends React.Component<{}, State> {
  state: State = {
    cardData: undefined,
  }

  componentDidMount() {
    this.getCardData()
  }

  getCardData = async () => {
    const personalData = await getPersonalData()
    const membershipData = await getMembershipData()

    const name = `${personalData.data.firstName} ${personalData.data.lastName}`
    const industry = membershipData.industry.name as 'Kultursektorn' | 'Kommunikation'

    this.setState({
      cardData: {
        ...this.state.cardData,
        name,
        industry,
      },
    })
  }

  renderCard = () => {
    if (!this.state.cardData) {
      return (
        <div className="u-marginVxlg">
          <LoaderEllipsis color="dark" />
        </div>
      )
    }

    const { name, industry } = this.state.cardData
    const cardClass = industry === 'Kultursektorn' ? 'card--beige' : ''

    return (
      <div className={`card ${cardClass}`}>
        <div className="card-logo"></div>
        <p className="card-label">Namn</p>
        <p className="card-name">{name}</p>
      </div>
    )
  }

  render() {
    return (
      <Authorize redirectOnAuth="/mina-sidor/" requiredRole="Prio">
        <div className="Page-container">
          <h1>Mitt kort</h1>
          <p>
            DIK-kortet använder du för att utnyttja erbjudanden som du får genom ditt medlemskap.
            <br />
            Det skulle kunna vara något inom personlig utveckling, ett event eller varför inte en
            utbildning?
          </p>

          {this.renderCard()}
        </div>
      </Authorize>
    )
  }
}

export default MittKort
