import * as React from 'react'
import SEO from '../../components/seo'
import MypagesApp from '../../components/Mypages/app'
import { AuthState } from 'store/auth/types'
import { graphql } from 'gatsby'
import Authorize from '../../components/Mypages/Authorize'
import { getInvoices } from 'apiConfig/apiMethods'
import { Invoice } from 'components/Mypages/fakturor'
import MyPages from 'components/Mypages/mypages'
import { pushPageViewPreventDuplicates } from '../../utils/gtmUtils'
import LayoutNew from 'layout/LayoutNew'

interface StateProps {
  auth: AuthState
}

interface Props extends StateProps {
  pageContext: any
  data: {
    contentfulWebsiteConfiguration?: {
      invoicePaymentDetails?: {
        invoicePaymentDetails?: string
      }
      coachHeading?: string
      coachPreamble?: { coachPreamble?: string }
      coachLink1Text?: string
      coachLink1Url?: string
      coachLink2Text?: string
      coachLink2Url?: string
      coachLink3Text?: string
      coachLink3Url?: string
      coachLink4Text?: string
      coachLink4Url?: string
    }
  }
  location: any
}

export default function MypagesTemplate({
  data: { contentfulWebsiteConfiguration: cmsConfig },
  ...props
}: Props) {
  const [invoices, setInvoices] = React.useState<Invoice[]>([])
  React.useEffect(() => {
    getInvoices().then(setInvoices)
  }, [])
  React.useEffect(() => {
    pushPageViewPreventDuplicates()
  }, [])

  const coachOptions = React.useMemo<React.ComponentProps<typeof MyPages>['coachOptionContent']>(
    () => ({
      heading: cmsConfig?.coachHeading,
      preamble: cmsConfig?.coachPreamble?.coachPreamble,
      links: [1, 2, 3, 4]
        .map((index) => ({
          text: cmsConfig?.[`coachLink${index}Text` as 'coachLink1Text'],
          url: cmsConfig?.[`coachLink${index}Url` as 'coachLink1Url'],
        }))
        .filter((link) => link.text != null && link.url != null) as {
        text: string
        url: string
      }[],
    }),
    [cmsConfig],
  )

  return (
    <Authorize redirectOnAuth="/logga-in/">
      <LayoutNew>
        <SEO description="" title="Mina sidor" />
        <div
          style={{
            paddingTop: '2rem',
          }}
        >
          <MypagesApp
            default
            path="mina-sidor"
            coachOptionsContent={coachOptions}
            invoicePaymentDetails={cmsConfig?.invoicePaymentDetails?.invoicePaymentDetails}
            invoices={invoices}
          />
        </div>
      </LayoutNew>
    </Authorize>
  )
}

export const queryMemberArticles = graphql`
  query {
    allContentfulMemberArticle {
      edges {
        node {
          heading
          preamble {
            preamble
          }
          body {
            raw
          }
          featuredImage {
            title
            description
            fluid {
              src
            }
          }
          publishDate
          showPublishDate
          metaTitle
          metaDescription {
            metaDescription
          }
        }
      }
    }
    contentfulWebsiteConfiguration {
      invoicePaymentDetails {
        invoicePaymentDetails
      }
      coachHeading
      coachPreamble {
        coachPreamble
      }
      coachLink1Text
      coachLink1Url
      coachLink2Text
      coachLink2Url
      coachLink3Text
      coachLink3Url
      coachLink4Text
      coachLink4Url
    }
  }
`
