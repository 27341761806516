import * as React from 'react'
import Button from 'components/button/Button'
import { injectIntl } from 'react-intl'
import Calendar from './calendar/calendar'
import {
  postBook,
  postUnbook,
  getMemberArticles,
  getPersonalizedMemberArticles as getPersonalizedArticles,
  getDikSessionArticles,
} from 'apiConfig/apiMethods'
import Notification from 'components/Notification/notification'
import ArrowDown from '../../images/Icons/arrow_drop_down.svg'
import Modal from 'components/modal/Modal'
import ArticleListing from './ArticleListing/ArticleListing'
import { ArticleData } from 'components/Article/Article'
import { CoachOptionProvider } from './CoachOptionContext'
import EventBooking from './EventBooking'
import StaticCoachBanner from './StaticCoachBanner'

interface ModalOptions {
  visible: boolean
  id?: 'cancelEvent' | 'bookEvent'
  ref: Partial<{
    courseId: string
    reservationId: string
    coachName: string
    name: string
    location: string
    status: string
    startDate: string
    endDate: string
    booked: boolean
    index: number
    activeTab: number
  }>
}

type ArticleTypes = 'personalized' | 'member' | 'dik-session'

export interface ArticleState {
  type?: ArticleTypes
  data?: ArticleData[]
  isLoadingMore: boolean
  isLoadingInit: boolean
  currentPage: number
  endOfArticles: boolean
}

interface State {
  event: string | false
  notification: boolean
  eventYear: string
  intersectingSpot?: string
  modalOptions: ModalOptions
  reload: number
  bookingMessage: string
  tabLayout: { use: boolean; index: number }
  isLoading: boolean
  articles: {
    personalized: ArticleState
    member: ArticleState
    ['dik-session']: ArticleState
  }
}

interface Props {
  intl: any
  coachOptionContent: React.ComponentProps<typeof StaticCoachBanner>
  default?: true
  path?: string
}

const initArticleState = (type: ArticleTypes) => ({
  type,
  data: undefined,
  isLoadingMore: false,
  isLoadingInit: false,
  currentPage: 0,
  endOfArticles: false,
})

const mobileMediaQuery =
  typeof matchMedia === 'function' ? matchMedia('(max-width: 768px)') : undefined

class MyPages extends React.Component<Props, State> {
  state: State = {
    event: false,
    notification: false,
    eventYear: new Date().getFullYear().toString(),
    modalOptions: {
      visible: false,
      ref: {},
    },
    tabLayout: { use: false, index: 0 },
    isLoading: false,
    reload: 0,
    bookingMessage: '',
    articles: {
      personalized: initArticleState('personalized'),
      member: initArticleState('member'),
      ['dik-session']: initArticleState('dik-session'),
    },
  }

  spotRefs: { [spot: string]: HTMLElement } = {}

  componentDidMount() {
    this.loadArticles('member', 'init')
    this.loadArticles('personalized', 'init')
    this.loadArticles('dik-session', 'init')
    mobileMediaQuery?.addListener?.(({ matches }) => this.setTabLayout(matches))
    mobileMediaQuery && this.setTabLayout(mobileMediaQuery.matches)
  }

  setTabLayout(isTabLayout: boolean) {
    isTabLayout != this.state.tabLayout.use &&
      this.setState((state) => ({
        tabLayout: { ...state.tabLayout, use: isTabLayout },
      }))
  }

  loadArticles = (type: ArticleTypes, mode: 'init' | 'more') => {
    const loadingState: keyof ArticleState = mode === 'init' ? 'isLoadingInit' : 'isLoadingMore'

    this.setState(
      {
        articles: {
          ...this.state.articles,
          [type]: {
            ...this.state.articles[type],
            [loadingState]: true,
          },
        },
      },
      () => this.getArticles(type),
    )
  }

  getArticles = async (type: ArticleTypes) => {
    const { currentPage, data } = this.state.articles[type]
    const page = currentPage + 1
    const pageSize = 4

    const articleData = await (async () => {
      switch (type) {
        case 'member':
          return await getMemberArticles({ page, pageSize })
        case 'personalized':
          return await getPersonalizedArticles({ page, pageSize })
        case 'dik-session':
          return await getDikSessionArticles({ page, pageSize })
        default: {
          const neverEverType: never = type
          throw new Error(`Cannot load articles of type ${neverEverType}`)
        }
      }
    })()

    if (!articleData?.length) {
      this.setState({
        articles: {
          ...this.state.articles,
          [type]: {
            ...this.state.articles[type],
            isLoadingMore: false,
            isLoadingInit: false,
            currentPage: page,
            endOfArticles: true,
          },
        },
      })
      return
    }

    const updatedArticles = data ? [...data, ...articleData] : articleData

    this.setState({
      articles: {
        ...this.state.articles,
        [type]: {
          ...this.state.articles[type],
          data: updatedArticles,
          isLoadingMore: false,
          isLoadingInit: false,
          currentPage: page,
          endOfArticles: articleData?.length < pageSize ? true : false,
        },
      },
    })
  }

  openEvent = (type: string) => {
    this.setState({ event: type })
  }

  notification = () => {
    this.setState({ event: false })

    this.setState({ notification: true })

    setTimeout(() => {
      this.setState({ notification: false })
    }, 5000)
  }

  onBookingSuccess = () => {
    this.setState({
      event: false,
      modalOptions: { visible: false, ref: null },
      reload: this.state.reload + 1,
      bookingMessage: 'Aktiviteten är bokad',
    })
    this.notification()
  }

  bookCourse = (courseId: string) => {
    postBook({ courseId }).then(this.onBookingSuccess)
  }

  unbook = (id: any) => {
    this.toggleModal({ visible: true, id: 'cancelEvent', ref: id })
    this.setState({ bookingMessage: 'Aktiviteten är avbokad' })
  }

  postBook = (id: any) => {
    this.toggleModal({ visible: true, id: 'bookEvent', ref: id })
  }

  toggleModal = (modalOptions: ModalOptions) => {
    this.setState({ modalOptions })
  }

  fetchEvents = () => {}

  renderModal = () => {
    const { modalOptions } = this.state

    if (!modalOptions.visible) {
      return null
    }

    const { startDate, endDate, name, courseId, coachName, seatsLeft, reservationId } =
      modalOptions.ref
    let dateStart = new Date(startDate)
    let dateEnd = new Date(endDate)
    let optionsDate = { weekday: 'short', month: 'short', day: 'numeric' }
    let optionsTime = { hour: '2-digit', minute: '2-digit' }

    const unbookEvent = () => {
      this.setState({ isLoading: true })
      postUnbook({ reservationId: reservationId }).then((res) => {
        this.toggleModal({ visible: false })
        this.setState({ reload: this.state.reload + 1 })
        this.notification()
        this.setState({ isLoading: false })
      })
    }

    switch (modalOptions.id) {
      case 'cancelEvent':
        return (
          <Modal
            key={modalOptions.id}
            onClose={() => this.toggleModal({ visible: false })}
            closeButton={true}
            closeOnEsc={true}
          >
            <div className="u-textCenter">
              <h4>{dateStart.toLocaleDateString('sv-SE', optionsDate)}</h4>
              <p>
                {dateStart.toLocaleTimeString('sv-SE', optionsTime)} -{' '}
                {dateEnd.toLocaleTimeString('sv-SE', optionsTime)}
              </p>
              <p>
                {name} {coachName && coachName !== '' ? `med ${coachName}` : null}
              </p>
              <Button
                isLoading={this.state.isLoading}
                className="button button--normal orange"
                onClick={() => unbookEvent()}
              >
                Avboka
              </Button>
            </div>
          </Modal>
        )

      case 'bookEvent':
        return (
          <Modal
            key={modalOptions.id}
            onClose={() => this.toggleModal({ visible: false })}
            closeButton={true}
            closeOnEsc={true}
          >
            <div className="u-textCenter">
              <h4>{dateStart.toLocaleDateString('sv-SE', optionsDate)}</h4>
              <p>
                {dateStart.toLocaleTimeString('sv-SE', optionsTime)} -{' '}
                {dateEnd.toLocaleTimeString('sv-SE', optionsTime)}
              </p>
              <p>
                {name} {coachName && coachName !== '' ? `med ${coachName}` : null}
              </p>
              <Button
                isLoading={this.state.isLoading}
                className="button button--normal orange"
                onClick={() => this.bookCourse(courseId)}
              >
                Boka
              </Button>
            </div>
          </Modal>
        )

      default:
        return null
    }
  }

  renderArticleListing = (key: keyof State['articles'], heading: string) => (
    <>
      {this.state.articles[key].data === undefined ||
        (this.state.articles[key].data?.length && (
          <p className="myPage-label u-marginBsm u-marginTxlg">{heading}</p>
        ))}
      <ArticleListing
        loadMore={() => this.loadArticles(key, 'more')}
        articleState={this.state.articles[key]}
        layoutMode="fitRows"
      />
    </>
  )

  render() {
    const { formatMessage } = this.props.intl
    return (
      <CoachOptionProvider>
        <div className="Page-container">
          <div className="u-sizeFull u-lg-size1of2">
            <h1>{formatMessage({ id: 'my-pages.headline' })}</h1>
          </div>
          <StaticCoachBanner {...this.props.coachOptionContent} />
          <div className="myPages-container u-marginBxlg">
            {this.state.tabLayout.use && (
              <ul className="myPages-tabs">
                <li
                  className={`myPages-tab${this.state.tabLayout.index === 0 ? ' is-active' : ''}`}
                >
                  <a
                    onClick={() =>
                      this.setState((state) => ({
                        tabLayout: { ...state.tabLayout, index: 0 },
                      }))
                    }
                  >
                    Aktuellt
                  </a>
                </li>
                <li
                  className={`myPages-tab${this.state.tabLayout.index === 1 ? ' is-active' : ''}`}
                >
                  <a
                    onClick={() =>
                      this.setState((state) => ({
                        tabLayout: { ...state.tabLayout, index: 1 },
                      }))
                    }
                  >
                    Kalender
                  </a>
                </li>
              </ul>
            )}
            <div className="Grid Grid--gutterH myPages-container-calendar">
              {(!this.state.tabLayout.use || this.state.tabLayout.index === 0) && (
                <div className="Grid-cell u-md-size2of3">
                  {this.renderArticleListing(
                    'personalized',
                    formatMessage({ id: 'my-pages.minaArtiklar' }),
                  )}
                  {this.renderArticleListing(
                    'dik-session',
                    formatMessage({ id: 'my-pages.dik-sessions' }),
                  )}
                  {this.renderArticleListing('member', formatMessage({ id: 'my-pages.artiklar' }))}
                </div>
              )}
              {(!this.state.tabLayout.use || this.state.tabLayout.index === 1) && (
                <div className="Grid-cell u-md-size1of3">
                  <p className="myPage-label u-marginBsm u-marginTxlg">
                    {formatMessage({ id: 'my-pages.calendar' })}
                  </p>
                  <div className="u-inlineBlock u-posRelative">
                    <select
                      className="myPages-select"
                      onChange={(e) => this.setState({ eventYear: e.target.value })}
                    >
                      <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                      <option value={new Date().getFullYear() + 1}>
                        {new Date().getFullYear() + 1}
                      </option>
                    </select>
                    <ArrowDown className="myPages-select-dropdown" />
                  </div>
                  <Calendar
                    year={Number(this.state.eventYear)}
                    unbook={this.unbook}
                    postBook={this.postBook}
                    reload={this.state.reload}
                  />
                </div>
              )}
            </div>
          </div>
          {this.renderModal()}
          {this.state.event && (
            <EventBooking
              type={this.state.event}
              onSuccess={this.onBookingSuccess}
              close={() => this.setState({ event: false })}
            />
          )}
          <Notification fade={this.state.notification} message={this.state.bookingMessage} />
        </div>
      </CoachOptionProvider>
    )
  }
}

export default injectIntl(MyPages)
