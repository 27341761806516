import React from "react"
import Panel from "./Panel"
import { EnvironmentPlugin } from "webpack";

interface Props {
  panels?: any
  bookedPanels?: any
  booked?:boolean
  unbook?: (unbook: {}) => void
  postBook?: (postBook: {}) => void
}

interface States {
  activeTab: number
}

export default class Accordion extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: -1
    };

    this.activateTab = this.activateTab.bind(this);
  }

  activateTab(index: number) {
    this.setState(prev => ({
      activeTab: prev.activeTab === index ? -1 : index
    }));
  }

  render() {
    const { panels, booked } = this.props;
    const { activeTab } = this.state;

    return (
      <div className='accordion' role='tablist'>
        {
          panels && panels.map((panel, index) =>
            <Panel
              key={index}
              activeTab={activeTab}
              index={index}
              {...panel}
              booked={booked}
              activateTab={this.activateTab.bind(null, index)}
              unbook={this.props.unbook}
              postBook={this.props.postBook}
            />
          )
        }
      </div>
    );
  }
}