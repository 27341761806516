import React from "react";
import ReactDOM from "react-dom";
import ArrowDown from "../../../images/Icons/arrow_drop_down.svg";
import ArrowUp from "../../../images/Icons/arrow_drop_up.svg";
import Warning from "../../../images/Icons/warning.svg";
import "../fakturor.less";
import * as copy from "../../../data/mypages.json";
import { Invoice } from "../fakturor";

type Props = {
  type: string;
  isActive: boolean;
  focus(): void;
  invoicePaymentDetails?: string;
} & Invoice;

export default class InvoiceDetail extends React.Component<Props> {
  state = {
    elementInnerPanelHeight: 0
  };

  element: Element | null = null;

  componentDidMount() {
    if (this.element != null) {
      const elementInnerPanelHeight = this.element.querySelector(
        ".Invoice-panel-inner"
      )?.scrollHeight;

      elementInnerPanelHeight &&
        this.setState({
          elementInnerPanelHeight
        });
    }
  }

  render() {
    const isActive = this.props.isActive;
    const innerStyle = {
      height: `${isActive ? this.state.elementInnerPanelHeight : 0}px`
    };
    let dueDate = new Date(this.props.dueDate);
    let targetDate = new Date(dueDate.setDate(dueDate.getDate() - 5));
    let now = new Date();

    return (
      <li className="Invoice-panelContainer" ref={ref => (this.element = ref)}>
        <div
          className="Invoice-panel"
          aria-expanded={isActive}
          onClick={this.props.focus}
        >
          {this.props.type === "unpaid" && (
            <p className="Invoice-panel-blue">
              <span>
                <span className="Heading Heading--h2 u-colorWhite u-marginBz u-marginRxsm u-inlineBlock">
                  {this.props.amount}
                </span>
                <span className="Heading Heading--h5 u-colorBeige u-marginBz u-inlineBlock">
                  kr
                </span>
              </span>
            </p>
          )}
          <div className="Invoice-panel-white">
            <div>
              <p className="Invoice-label u-marginAz">
                {copy.myInvoices.dueDate}
              </p>
              <p
                className={`u-marginAz ${
                  this.props.type === "unpaid" && now > targetDate
                    ? "u-colorWarning"
                    : ""
                }`}
              >
                {this.props.type === "unpaid" && now > targetDate ? (
                  <>
                    <Warning className="u-fillWarning u-alignSub" />{" "}
                    <span className="u-textNoWrap">
                      {new Date(this.props.dueDate).toLocaleDateString("sv-SE")}
                    </span>
                  </>
                ) : (
                  new Date(this.props.dueDate).toLocaleDateString("sv-SE")
                )}
              </p>
            </div>
            {isActive ? <ArrowUp /> : <ArrowDown />}
          </div>
        </div>
        <div
          className="Invoice-panel-inner"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <ul className="List Invoice-list">
            <li className="Invoice-listItem u-marginB">
              <span className="Invoice-label">
                {copy.myInvoices.invoiceNumber}
              </span>
              {this.props.invoiceNumber}
            </li>
            <li className="Invoice-listItem u-marginB">
              <span className="Invoice-label">{copy.myInvoices.amount}</span>
              {this.props.amount} {copy.myInvoices.currency}
            </li>
            <li className="Invoice-listItem u-marginB">
              <span className="Invoice-label">{copy.myInvoices.issued}</span>
              {new Date(this.props.issued).toLocaleDateString("sv-SE")}
            </li>
            <li className="Invoice-listItem u-marginB">
              <span className="Invoice-label">{copy.myInvoices.ocr}</span>
              {this.props.ocr}
            </li>
            {this.props.type === "unpaid" && (
              <li className="Invoice-listItem u-marginB">
                <span className="Invoice-label">
                  {copy.myInvoices.amountPaid}
                </span>
                {this.props.amount - this.props.amountDue}{" "}
                {copy.myInvoices.currency}
              </li>
            )}
            <li className="Invoice-listItem u-marginB">
              <span className="Invoice-label">{copy.myInvoices.dueDate}</span>
              {new Date(this.props.dueDate).toLocaleDateString("sv-SE")}
            </li>
            {this.props.type === "unpaid" && (
              <li className="Invoice-listItem u-marginB">
                <span className="Invoice-label">{copy.myInvoices.paysTo}</span>
                <span style={{ whiteSpace: "pre-line" }}>
                  {this.props.invoicePaymentDetails}
                </span>
              </li>
            )}
          </ul>
        </div>
      </li>
    );
  }
}
