import React from "react";
import "./mypages.less";
import InvoiceAccordion from "./Invoice/InvoiceAccordion";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { InjectedIntl, injectIntl } from "react-intl";

export type Invoice = {
  status: "paid" | "unpaid";
  paymentPeriod: string;
  issued: Date;
  dueDate: Date;
  invoiceNumber: string;
  ocr: string;
  amount: number;
  amountDue: number;
};

type Props = {
  intl: InjectedIntl;
  invoicePaymentDetails?: string;
  path: string;
  invoices: Invoice[];
};

const Fakturor = (props: Props) => {
  const {
    intl: { formatMessage },
    invoicePaymentDetails,
    invoices
  } = props;

  const [unpaidInvoices, paidInvoices] = invoices.reduce(
    (acc, invoice) => {
      switch (invoice.status) {
        case "unpaid": {
          acc[0].push(invoice);
          break;
        }
        case "paid": {
          acc[1].push(invoice);
          break;
        }
      }
      return acc;
    },
    [[] as Invoice[], [] as Invoice[]] as const
  );

  return (
    <div className="Page-container">
      <h1 className="Heading Heading--h1">
        {formatMessage({ id: "myInvoices.headline" })}
      </h1>
      <p style={{whiteSpace: "pre-wrap"}}>{formatMessage({ id: "myInvoices.preamble" })}</p>

      {/* Mobile only */}
      <Tabs className="u-marginBxlg u-md-hidden">
        <TabList className="List u-flex u-flexJustifyCenter u-marginB">
          <Tab
            className="myPages-label-mobile u-marginRsm"
            selectedClassName="myPages-toggle"
          >
            {formatMessage({ id: "myInvoices.unpaid" })}
          </Tab>
          <Tab
            className="myPages-label-mobile u-marginLsm"
            selectedClassName="myPages-toggle"
          >
            {formatMessage({ id: "myInvoices.paid" })}
          </Tab>
        </TabList>
        <TabPanel>
          <div className="Grid-cell u-md-size1of2">
            <InvoiceAccordion
              invoices={unpaidInvoices}
              type="unpaid"
              invoicePaymentDetails={invoicePaymentDetails}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className="Grid-cell u-md-size1of2">
            <InvoiceAccordion invoices={paidInvoices} type="paid" />
          </div>
        </TabPanel>
      </Tabs>

      {/* Desktop only */}
      <section className="Grid Grid--gutterA u-marginBxlg u-hidden u-md-flex">
        {unpaidInvoices.length ? (
          <div className="Grid-cell u-md-size1of2">
            <h2 className="myPages-label">
              {formatMessage({ id: "myInvoices.unpaid" })}
            </h2>
            <InvoiceAccordion
              invoices={unpaidInvoices}
              type="unpaid"
              invoicePaymentDetails={invoicePaymentDetails}
            />
          </div>
        ) : null}

        {paidInvoices.length ? (
          <div className="Grid-cell u-md-size1of2">
            <h2 className="myPages-label">
              {formatMessage({ id: "myInvoices.paid" })}
            </h2>
            <InvoiceAccordion invoices={paidInvoices} type="paid" />
          </div>
        ) : null}
      </section>
    </div>
  );
};

export default injectIntl(Fakturor);
