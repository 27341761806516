import { useEffect } from "react";

export default function useLockedBodyYScroll() {
  useEffect(() => {
    const prevOverflowY = document.body.style.overflowY;
    document.body.style.overflowY = "hidden";
    const prevHeight = document.body.style.height;
    document.body.style.height = "100vh";
    return () => {
      prevHeight == null
        ? delete document.body.style.height
        : (document.body.style.height = prevHeight);
      prevOverflowY == null
        ? delete document.body.style.overflowY
        : (document.body.style.overflowY = prevOverflowY);
    };
  }, []);
}
