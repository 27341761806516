import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import "./podcast.less";
import Play from "../../images/Icons/play_circle_outline.svg";
import Pause from "../../images/Icons/pause_circle_outline.svg";
import wave from "../../images/waveform.svg";

interface Props {
  podId: string;
  children?: any;
}

const Podcast = (props: Props) => {
  const { podId, children } = props;
  const [url, setUrl] = useState<string>();
  const [play, setplay] = useState(false);

  useEffect(() => {
    const regex = /<meta.*?name="twitter:player:stream".*?content="(.*?)".*?>/i;

    axios
      .get(`https://dialog.libsyn.com/podcast/episode/${podId}`)
      .then(data => {
        let podUrl: any = regex.exec(data.data);
        podUrl = podUrl[1].replace(/^http:\/\//i, "https://");
        setUrl(podUrl);
      });
  }, []);

  const audioElement = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    audioElement.current &&
      (play ? audioElement.current.play() : audioElement.current.pause());
  }, [play]);

  return (
    <div className="podcast u-textEllipsis">
      {children}
      <div
        className={`podcast-container ${!children &&
          "u-marginTz u-flexJustifyCenter"}`}
      >
        <audio src={url} preload="none" ref={audioElement}/>
        <button className="podcast-button" onClick={() => setplay(!play)}>
          {!play ? (
            <Play width="48" height="48" />
          ) : (
            <Pause width="48" height="48" />
          )}
        </button>
        <img style={{ overflow: "hidden" }} src={wave} />
      </div>
    </div>
  );
};

export default Podcast;
