import { connect } from "react-redux";
import { ApplicationState } from "store";
import { AuthState } from "store/auth/types";
import { Redirect } from "@reach/router";
import React, { ReactNode } from "react";
import { MemberRoles } from "components/onboarding/bankID/types";

type Props = {
  auth: AuthState;
  redirectOnAuth: string;
  children: ReactNode;
  requiredRole?: MemberRoles;
};

function Authorize({ auth, redirectOnAuth, children, requiredRole }: Props) {
  if (auth.status === "UNVERIFIED") {
    return null;
  }
  if (
    auth.status === "LOGGED_IN" &&
    (requiredRole === undefined || requiredRole === auth.role)
  ) {
    return <>{children}</>;
  } else {
    return <Redirect to={redirectOnAuth} noThrow={true} />;
  }
}

export default connect((state: ApplicationState) => ({ auth: state.auth }))(
  Authorize
);
