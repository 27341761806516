import posed from "react-pose";

const staggerDuration = 2

export const SignupInput = posed.li({
    before: { y: '100vh', scale: 1 },

    enter: {
        y: 0,
        transition: {
            duration: 100,
            y: { flip: true, type: 'spring', stiffness: 300, damping: 200 },
        },

    },
    exit: {
        y: 0,
        transition: {
            duration: 100,
            y: { flip: true, type: 'spring', stiffness: 500, mass: 1000},

        }},
    activeCard: {
        backgroundColor: '#fff',
        height: '90vh'
    },
    closed: {
        display: "none"
    }
})
