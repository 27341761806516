import React from "react";
import InvoiceDetail from "./InvoiceDetail";
import { Invoice } from "../fakturor";

interface Props {
  invoices: Invoice[];
  type: "paid" | "unpaid";
  invoicePaymentDetails?: string;
}

interface States {
  activePanel: number;
}

export default class InvoiceAccordion extends React.Component<Props, States> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activePanel: -1
    };
  }

  activatePanel = (index: number) => {
    this.setState(prev => ({
      activePanel: prev.activePanel === index ? -1 : index
    }));
  };

  render() {
    return (
      <ul className="List u-marginVz">
        {this.props.invoices.map((invoice, index) => (
          <InvoiceDetail
            key={index}
            isActive={this.state.activePanel === index}
            focus={() => this.activatePanel(index)}
            type={this.props.type}
            invoicePaymentDetails={this.props.invoicePaymentDetails}
            {...invoice}
          />
        ))}
      </ul>
    );
  }
}
