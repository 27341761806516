import React, { useState, useEffect } from 'react'
import './mypages.less'
import locale from '../../data/onboarding.json'
import Layout from '../../layout/Layout'
import SEO from 'components/seo'
import { graphql } from 'gatsby'
import { get } from 'lodash'
import { injectIntl } from 'react-intl'
import { flattenObject } from '../../utils/tools'
import ArticleListing from './ArticleListing/ArticleListing'
import { ArticleState } from './mypages'
import { getMemberOfferArticles } from 'apiConfig/apiMethods'

interface Props {
  intl: any
}

interface State {
  articles: ArticleState
}

const flattedLocale = flattenObject(locale)

class MinaFormaner extends React.Component<Props, State> {
  state: State = {
    articles: {
      data: undefined,
      isLoadingMore: false,
      isLoadingInit: false,
      currentPage: 0,
      endOfArticles: false,
    },
  }

  componentDidMount() {
    this.loadArticles('init')
  }

  loadArticles = (mode: 'init' | 'more') => {
    const loadingState: keyof ArticleState = mode === 'init' ? 'isLoadingInit' : 'isLoadingMore'

    this.setState(
      {
        articles: {
          ...this.state.articles,
          [loadingState]: true,
        },
      },
      () => this.getArticles(),
    )
  }

  getArticles = async () => {
    const { currentPage, data } = this.state.articles
    const page = currentPage + 1
    const pageSize = 6
    const articleData = await getMemberOfferArticles({ page, pageSize })

    if (!articleData?.length) {
      this.setState({
        articles: {
          ...this.state.articles,
          isLoadingMore: false,
          isLoadingInit: false,
          currentPage: page,
          endOfArticles: true,
        },
      })
      return
    }

    const updatedArticles = data ? [...data, ...articleData] : articleData

    this.setState({
      articles: {
        ...this.state.articles,
        data: updatedArticles,
        isLoadingMore: false,
        isLoadingInit: false,
        currentPage: page,
        endOfArticles: articleData?.length < pageSize ? true : false,
      },
    })
  }

  render() {
    const { formatMessage } = this.props.intl

    return (
      <>
        <div className="Page-container">
          <div className="u-sizeFull u-lg-size1of2">
            <h1>{formatMessage({ id: 'formaner.headline' })}</h1>
            <p>{formatMessage({ id: 'formaner.preamble' })}</p>
          </div>
        </div>
        <div className="u-colorBgWhite u-flexGrow u-paddingTmd">
          <div className="Page-container grid-container">
            <ArticleListing
              loadMore={() => this.loadArticles('more')}
              articleState={this.state.articles}
              rowCount={3}
              layoutMode="fitRows"
            />
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(MinaFormaner)
