import React, { useState, useCallback, useEffect, useContext, useMemo, useRef } from 'react'
import { PoseGroup } from 'react-pose'
import { SignupInput } from './calendar/animations'
import Spots from './calendar/spots'
import Timeslots from './calendar/timeslots'
import BookingConfirm from './calendar/bookingconfirm'
import { getAllSpots, Spot, postBook } from 'apiConfig/apiMethods'
import ArrowBack from '../../images/Icons/arrow_back.svg'
import Close from '../../images/Icons/close.svg'
import CoachOptionContext from './CoachOptionContext'
import Button from 'components/button/Button'
import useLockedBodyYScroll from '../../useLockedBodyYScroll'

type Props = {
  type: string
  close(): void
  onSuccess(spot: Spot): void
}

export default function EventBooking({ type, close, onSuccess }: Props) {
  useLockedBodyYScroll()

  const [[year, month], setCalendarMonth] = useState<[number, number]>([
    new Date().getFullYear(),
    new Date().getMonth(),
  ])

  const setYearAndMonth = useCallback(
    (...params: [number, number]) => {
      setCalendarMonth(params)
    },
    [setCalendarMonth],
  )

  const [selectedDate, selectDate] = useState<Date>()

  const [spots, setSpots] = useState<Spot[]>([])

  const [selectedSpot, selectSpot] = useState<Spot>()

  const lastPromise = useRef<Promise<Spot[]>>()

  useEffect(() => {
    const currentPromise = getAllSpots({ month: month + 1, year, type }).then((res) => res)

    lastPromise.current = currentPromise

    currentPromise.then((res) => {
      if (currentPromise === lastPromise.current) {
        setSpots(res)
      }
    })
  }, [month, year, type])

  const coachOptionContext = useContext(CoachOptionContext)

  const coachData = useMemo(
    () => type.toLowerCase() in coachOptionContext && coachOptionContext[type.toLowerCase()],
    [coachOptionContext, type],
  )

  const [[selectedCoachOption, coachOptionLocked], selectCoachOption] = useState([
    null as string | null,
    false,
  ])

  const setCoachOption = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      selectCoachOption([e.target.value, false])
    },
    [selectCoachOption],
  )

  const lockCoach = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()
      selectedCoachOption && selectCoachOption([selectedCoachOption, true])
    },
    [selectCoachOption, selectedCoachOption],
  )

  useEffect(() => {
    if (selectedCoachOption == null && coachData && coachData.options[0]) {
      selectCoachOption([coachData.options[0], false])
    }
  }, [coachData, selectCoachOption])

  const goBack = useCallback(() => {
    if (selectedSpot) {
      selectSpot(undefined)
      return
    }
    if (selectedDate) {
      selectDate(undefined)
      return
    }
    if (coachData != null && coachOptionLocked) {
      selectCoachOption([selectedCoachOption, false])
      return
    }
    close()
  }, [
    selectedSpot,
    selectedDate,
    selectSpot,
    selectDate,
    close,
    coachOptionLocked,
    selectedCoachOption,
  ])

  const [isLoading, setIsLoading] = useState(false)
  const submitBooking = useCallback(
    (id: string) => {
      setIsLoading(true)
      postBook({ courseId: id, option: selectedCoachOption ?? undefined }).then((_res) => {
        onSuccess(selectedSpot!)
      })
    },
    [setIsLoading, selectedSpot],
  )

  return (
    <ul className="calendar-card">
      <PoseGroup preEnterPose="before" animateOnMount>
        <SignupInput className={`userdetails__item`} key="tesitng">
          <div className="eventModal">
            <div className="u-flex u-flexJustifySpaceBetween u-marginBmd">
              <button className="button u-paddingLz" onClick={goBack}>
                <ArrowBack />
              </button>
              <h5>Boka {type}</h5>
              <button className="button u-paddingRz" onClick={close}>
                <Close />
              </button>
            </div>
            {coachOptionLocked == false && coachData ? (
              <form onSubmit={lockCoach} method="post">
                <h4 style={{ textAlign: 'center' }}>Välj inriktning</h4>
                {coachData.options.map((option) => (
                  <div className="Radio u-marginVsm" key={option}>
                    <input
                      id={option}
                      name="coach-option"
                      type="radio"
                      value={option}
                      onChange={setCoachOption}
                      checked={selectedCoachOption === option}
                      className="Radio-input"
                    />
                    <label htmlFor={option} className="Radio-label">
                      {option}
                    </label>
                  </div>
                ))}
                <div style={{ textAlign: 'center' }} className="u-marginVxsm">
                  <Button type="submit" className="button button--normal orange">
                    Välj ämne
                  </Button>
                </div>
              </form>
            ) : selectedDate == null ? (
              <Spots
                spots={spots}
                selectedDay={selectDate}
                updatedMonth={setYearAndMonth}
                type={type}
                year={year}
                month={month}
              />
            ) : selectedSpot == null ? (
              <Timeslots
                spots={spots.filter((spot) => {
                  const startDate = new Date(spot.startDate)
                  return (
                    startDate.getFullYear() === selectedDate.getFullYear() &&
                    startDate.getMonth() === selectedDate.getMonth() &&
                    startDate.getDate() === selectedDate.getDate()
                  )
                })}
                selectSpot={selectSpot}
              />
            ) : (
              <BookingConfirm
                isLoading={isLoading}
                spot={selectedSpot}
                submitBooking={submitBooking}
              />
            )}
          </div>
        </SignupInput>
      </PoseGroup>
    </ul>
  )
}
