import * as React from "react";
import {ComponentOptionData} from "components/onboarding/tunnel/types";

interface Props {
    activeSuggestion: number,
    index: number,
    key: string,
    onClick: (value: {name: string, id: string}) => void
    value: {name: string, id: string},
    GUID: string
}

const Suggestion = (props: any) => {
    const onClick = () => {
        props.onClick({name: props.value, id: props.GUID ? props.GUID : props.value})
    }

    return (
        <li className={props.activeSuggestion === props.index ? `suggestion--active` : ``} data-value={props.value} onClick={onClick} >
            {props.value}
        </li>
    )
}

export default Suggestion