import React from "react";
import { Link } from "gatsby";
import Podcast from "components/Podcast/Podcast";
import Play from "../../images/Icons/play_circle_outline.svg";
import { get } from "lodash";

export interface ArticleData {
  navigationName: string;
  slug: string;
  metaTitle: string;
  metaDescription: string;
  heading: string;
  preamble: string;
  preambleList: string;
  body: string;
  publishDate: string;
  showPublishDate: boolean;
  featuredImageUrl: string;
  featuredImageTitle: string;
  featuredImageDescription: string;
  embeddedPlayer?: {
    type: "Video" | "Podcast";
    heading: string;
    coverImageUrl: string;
    embedId: string;
  };
  callToAction: {
    type: string;
    name: string;
    body: string;
    buttonText: string;
    linkToInternalArticle: string;
    linkToInternalFileUrl: string;
    linkToInternalFileTitle: string;
    linkToInternalFileDescription: string;
    linkToExternalUrl: string;
    date: string;
  };
}

interface Props extends ArticleData {
  index?: number;
  url: string;
}

const Article = (props: Props) => {
  const {
    url,
    featuredImageUrl,
    featuredImageTitle,
    publishDate,
    showPublishDate,
    heading,
    embeddedPlayer,
    preamble,
    preambleList,
  } = props;

  const loadVideo = (id: string, e: any) => {
    e.currentTarget.parentElement.style.paddingBottom = "56.25%";
    e.currentTarget.parentElement.innerHTML = `
    <iframe id="ytplayer" type="text/html" width="100%" allow="autoplay"
    src="https://www.youtube.com/embed/${id}?autoplay=1&origin=https://dik.se"
    frameborder="0"></iframe>
    `;
  };

  const [imageUrl, imageTitle] =
    featuredImageUrl != null
      ? [featuredImageUrl, featuredImageTitle]
      : embeddedPlayer != null
      ? embeddedPlayer.coverImageUrl != null
        ? [embeddedPlayer.coverImageUrl, embeddedPlayer.heading]
        : [
            embeddedPlayer?.type === "Video"
              ? `https://img.youtube.com/vi/${embeddedPlayer.embedId}/maxresdefault.jpg`
              : "",
            "",
          ]
      : ["", ""];

  return (
    <article>
      {embeddedPlayer && embeddedPlayer.type == "Video" ? (
        <React.Fragment>
          <div className="list-video">
            <button
              className="list-video-button"
              onClick={(e) => {
                loadVideo(embeddedPlayer.embedId, e);
              }}
            >
              <Play width="100" height="100" className="list-video-play" />
              <img className="u-colorBgBeige" src={imageUrl} />
            </button>
          </div>
          <p className="article-date">
            {showPublishDate
              ? new Date(publishDate).toLocaleDateString("sv-SE", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : " "}
          </p>
          <Link className="u-inlineBlock" to={url}>
            <h1 className="Heading Heading--h4 Link Link--noUnderline u-marginBz">{heading}</h1>
            <p>{preambleList?.length ? preambleList : preamble}</p>
          </Link>
        </React.Fragment>
      ) : embeddedPlayer && embeddedPlayer.type == "Podcast" ? (
        <Podcast podId={embeddedPlayer.embedId}>
          <p className="article-date">
            {showPublishDate
              ? new Date(publishDate).toLocaleDateString("sv-SE", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : " "}
          </p>
          <h1 className="Heading Heading--h4">
            <Link className="Link Link--noUnderline" to={url}>
              {heading}
            </Link>
          </h1>
        </Podcast>
      ) : (
        <Link className="u-inlineBlock" to={url}>
          <img className="article-image" src={imageUrl} alt={imageTitle} />
          <p className="article-date">
            {showPublishDate
              ? new Date(publishDate).toLocaleDateString("sv-SE", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })
              : " "}
          </p>
          <h1 className="Heading Heading--h4 Link Link--noUnderline">{heading}</h1>
          <p>{preambleList?.length ? preambleList : preamble}</p>
        </Link>
      )}
    </article>
  );
};

export default Article;
