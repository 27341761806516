import React, {SyntheticEvent} from 'react';
import posed from "react-pose";
import "./FreeTextInput.less";
import Button from 'components/button/Button';
import useForm from "components/onboarding/FreeTextInput/useForm"
import validate from "components/onboarding/FreeTextInput/validationRules";
import {useState, useEffect} from 'react';
import { FormattedMessage, injectIntl, InjectedIntl } from 'react-intl'
import Suggestion from "components/onboarding/FreeTextInput/suggestion";
import {get} from 'lodash'
import { globalHistory } from '@reach/router';

type Suggestion = {id: string, name:string}

export interface FreeTextInputProps {
    onSubmit: (id: string, value: {name: string, id: string} | string, guid: string, valueName: string) => void,
    onUpdated?: (value: string) => void,
    componentOptionsList?: {[value: string]: Suggestion[]},
    validationMessage?: string,
    id: string
    currentValue: string
    type?: string
    hasButton?: boolean
    hasLabel?: boolean
    multiLine?: boolean
    render?: (status: boolean) => void
    style?: object,
    intl: InjectedIntl,
    children: React.ReactNode
}

export enum Keys {
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    ENTER = 'Enter'
}


function resolveInputType(type?:string) {

    let resolved = 'text'

    switch (type) {
        case "inkomst":
        case 'lon':
            resolved = 'tel'
        break;
    }
    return resolved
}

const FreeTextInput = (props: FreeTextInputProps) => {
    const { formatMessage } = props.intl
    const { value, valid, handleChange, handleSubmit, setValue, triedSubmitting } = useForm(props.currentValue, props.type || '', () => props.onSubmit(props.id, value === get(guid, "name") ? get(guid, "id") : value, get(guid, "id"), value), validate);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([])
    const [isEditMode, setIsEditMode] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [activeSuggestion, setActiveSuggestion] = useState(0)
    const validationPose = valid ? 'valid' : 'invalid'
    const labelPose = isEditMode || (typeof props.currentValue !== 'undefined' && props.currentValue.length) ? 'onFocus' : 'onBlur'
    const formClassName = `${isEditMode && 'form--isEditMode'}`
    const intlId = `inputlabels.${props.id}`
    const [touched, setTouched] = useState(false)
    const keyLimit = 1
    const updateInterval = 200
    const intlPlaceholder = `placeholders.${props.id}`;
    const [guid, setGuid] = useState()

    useEffect(() => {
        let timeout: number | null = null;
        if (props.onUpdated && isDirty && !touched && typeof value !== 'undefined' && value.length > keyLimit && !get(props.componentOptionsList, value)) {
            timeout = setTimeout( props.onUpdated, updateInterval, value, valid)
        }

        setTouched(false)
        return () => {
            timeout && clearTimeout(timeout);
        }
    }, [value]);



    useEffect(() => {
        const filteredSuggestions = props.componentOptionsList?.[value] ?? []
        setSuggestions(Array.isArray(filteredSuggestions) ? filteredSuggestions : [filteredSuggestions])
    }, [props.componentOptionsList, value]);

    const onSuggestionClick = (suggestion: {name: string, id: string}) => {
        setGuid({id: suggestion.id, name: suggestion.name})
        setValue(suggestion.name)
        setTouched(true)
        setIsDirty(false)
    }

    function onFocusChange(isFocused:boolean) {
        if (value === '') {
            setIsEditMode(isFocused)
        }
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {

        const key = event.key

        setIsDirty(true)

        if (suggestions.length && !touched) {

            if (key === Keys.ARROW_DOWN || key === Keys.ARROW_UP) {
                const direction:number = key === Keys.ARROW_UP ? activeSuggestion -1 : activeSuggestion + 1
                if (direction > -1 && direction < suggestions.length) {

                    setActiveSuggestion(direction)
                }
            }
            else if (key === Keys.ENTER) {
                setGuid({id: suggestions[activeSuggestion].id, name: suggestions[activeSuggestion].name})
                setValue(suggestions[activeSuggestion].name || '')
                setTouched(true)
                setIsDirty(false)

                event.preventDefault()
            }
        }
    }
    
    useEffect(() => {
        props.currentValue === "" ? setIsEditMode(false) : setIsEditMode(true)
    }, [props.currentValue])
    
    return (
            <form onSubmit={handleSubmit} className={formClassName} noValidate method="post">
                <div className="FreeTextInput" style={props.style}>

                {
                    props.hasLabel && (
                        <Label pose={labelPose}>
                            <FormattedMessage id={intlId} />
                        </Label>
                    )
                }

                {
                    props.multiLine ? (
                        <textarea autoComplete="off"
                            className="input"
                            name="value"
                            onFocus={onFocusChange.bind(null, true) }
                            onBlur={onFocusChange.bind(null, false) }
                            onChange={handleChange}
                            style={{ resize: "none" }}
                            onKeyDown={onKeyDown}
                            placeholder={formatMessage({ "id": intlPlaceholder })}
                            value={value}
                            required />
                    ) : (
                        <input autoComplete="off"
                            className="input"
                            type={resolveInputType(props.type).length ? resolveInputType(props.type) : "text"}
                            name="value"
                            onFocus={onFocusChange.bind(null, true) }
                            onBlur={onFocusChange.bind(null, false) }
                            onChange={handleChange}
                            onKeyDown={onKeyDown}
                            placeholder={formatMessage({ "id": intlPlaceholder })}
                            value={value}
                            required />
                    )
                }


                {props.render && props.render(valid)}

                <div className="freeTextInput-validation-message">
                    <Validation pose={validationPose && triedSubmitting ? validationPose : 'valid' }>{props.validationMessage}</Validation>
                </div>



                <ul className={`FreeTextInput-dropdown ${suggestions.length && 'active'}`} >
                    {
                        isDirty && suggestions.map((option: { [key: string]: any }, index: number) => {
                            return (
                                <Suggestion
                                    activeSuggestion={activeSuggestion}
                                    key={option.id}
                                    GUID={option.id}
                                    index={index}
                                    onClick={onSuggestionClick}
                                    value={option.name}/>
                            )
                        }
                        )
                    }
                </ul>

                </div>                
                {props.children}
                {
                    props.hasButton && (
                        <div className={`button-container u-flex u-flexJustifyEnd u-sizeFull ${props.validationMessage || props.children ? " u-marginTsm" : " u-marginTxlg" }`}>
                            <Button type="submit" className={`button button--normal orange ${!valid && "disabled"}`}>{formatMessage({"id": "buttons.next"})}</Button>
                        </div>
                    )
                }

            </form>

    )
}

FreeTextInput.defaultProps = {
    hasButton: true,
    hasLabel: true
}


//FreeTextInput.whyDidYouRender = true

// Input field title animation
const transition = { default: { ease: [.25, .1, .25, 1], duration: 200 } }
const Label = posed.label({
    onFocus: { y: 0, x: -5, scale: 0.9, transition },
    onBlur: { y: 35, x: 0, scale: 1.0, transition }
})

const Validation = posed.p(
    {
        valid: {y: -25},
        invalid: {y: 0}
    }
)



export default injectIntl(FreeTextInput)
