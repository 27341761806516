import { useState, useEffect, SyntheticEvent } from 'react';
import {Validate} from "components/onboarding/FreeTextInput/validationRules"

const useForm = (currentValue: string, type: string, callback: () => void, validate: Validate) => {

    let [value, setValue] = useState(currentValue)
    const [valid, setValid] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [triedSubmitting, setTriedSubmitting] = useState(false);

    useEffect(() => {

    }, [])

    // Prevent a cached value from fucking things up
    useEffect(() => {
        setIsSubmitting(false)
        setTriedSubmitting(false)
        setValue(currentValue)
        setValid(validate(type, currentValue))
    }, [currentValue])



    useEffect(() => {
        if (valid && isSubmitting) {
            callback()
        }
    }, [isSubmitting]);

    const handleSubmit = (e:SyntheticEvent) => {
        const isValidContent = validate(type, value)
        setIsSubmitting(true);
        setTriedSubmitting(true)
        setValid(isValidContent);


        e.preventDefault();
    }

    const handleChange = (e:SyntheticEvent) => {

        const nextValue = (e.target as HTMLInputElement).value


        setIsSubmitting(false);

        e.persist();
        if (validate) {
            setValid(validate(type, nextValue))
        }


        if (nextValue !== value) {
            setValue((e.target as HTMLInputElement).value)
        }
    }

    return {
        handleChange,
        handleSubmit,
        value,
        valid,
        setValue,
        isSubmitting,
        triedSubmitting
    }
};

export default useForm;