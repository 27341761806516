import React from "react";
import Button from "components/button/Button";

interface Props {
  spot: {
    courseId: string;
    coachName: string;
    name: string;
    location: string;
    status: string;
    startDate: string;
    endDate: string;
    seatsLeft: number;
  };
  submitBooking: (id: string, coachOption?: string) => void;
  isLoading: boolean;
}

const BookingConfirm = (props: Props) => {
  const {
    startDate,
    endDate,
    name,
    courseId,
    coachName,
    seatsLeft
  } = props.spot;
  let dateStart = new Date(startDate);
  let dateEnd = new Date(endDate);
  let optionsDate = { weekday: "short", month: "short", day: "numeric" };
  let optionsTime = { hour: "2-digit", minute: "2-digit" };

  return (
    <div className="u-textCenter">
      <h4>{dateStart.toLocaleDateString("sv-SE", optionsDate)}</h4>
      <p>
        {dateStart.toLocaleTimeString("sv-SE", optionsTime)} -{" "}
        {dateEnd.toLocaleTimeString("sv-SE", optionsTime)}
      </p>
      <p>
        {name} {coachName != "" ? `med ${coachName}` : null}
      </p>
      <Button
        isLoading={props.isLoading}
        className="button button--normal orange"
        onClick={() => props.submitBooking(courseId)}
      >
        {seatsLeft <= 0 ? "Ställ dig i kö" : "Bekräfta"}
      </Button>
    </div>
  );
};

export default BookingConfirm;
