import { fetch, fetchWithBody } from '../utils/axios'
import { CancellationCause } from 'components/Mypages/uppgifter'
import { MembershipData, MembershipStatus } from 'config/types'
import { ArticleData } from 'components/Article/Article'
import { Invoice } from 'components/Mypages/fakturor'
import { AxiosResponse } from 'axios'

interface SacoData {
  data: {
    token: string
  }
}

export type Spot = {
  coachName: string
  courseId: string
  endDate: string
  location: string
  name: string
  seatsLeft: number
  startDate: string
  status: string
}

export const getAllSpots = (payload: { year: number; month: number; type: string }) => {
  return fetch(`api/v1/courses/coach/spots`, 'POST', payload, { withCredentials: true }).then(
    (res: AxiosResponse<{ data: { courses: Spot[] } }>) => res.data.data.courses,
  )
}

export const getAllSpotTypes = () => {
  return fetch(`api/v1/courses/coach/types`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data.data.types,
  )
}

export const getAllEvents = () => {
  return fetch('api/v1/courses/events', 'GET', {}, { withCredentials: true }).then(
    (res) => res.data.data.courses,
  )
}

export const getAllBookedEvents = () => {
  return fetch('api/v1/courses/booked', 'GET', {}, { withCredentials: true }).then(
    (res) => res.data.data.courses,
  )
}

export const postBook = ({ courseId, option }: { courseId: string; option?: string }) => {
  return fetch('api/v1/courses/book', 'POST', { courseId, option }, { withCredentials: true }).then(
    (res) => console.log('booking confirmed', courseId, res),
  )
}

export const postUnbook = (payload: { reservationId: string }) => {
  return fetch('api/v1/courses/cancel', 'POST', payload, { withCredentials: true }).then((res) =>
    console.log('booking cancel', payload.reservationId, res),
  )
}

export const getPersonalData = () => {
  return fetch(`api/v1/member/personaldata`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data,
  )
}

export const postPersonalData = (payload: { phone: string; email: string }) => {
  return fetchWithBody(`api/v1/member/personaldata`, 'POST', payload, {
    withCredentials: true,
  }).then((res) => res.data)
}

export const getMembershipData = () => {
  return fetch(`api/v1/member/membership/data`, 'GET', {}, { withCredentials: true }).then(
    (res) => <MembershipData>res.data.data,
  )
}

export const getMembershipStatus = () => {
  return fetch(`api/v1/member/membership/status`, 'GET', {}, { withCredentials: true }).then(
    (res) => <MembershipStatus>res.data.data,
  )
}

export const getCancellationCauses = () => {
  return fetch(
    `api/v1/member/membership/cancellationcauses`,
    'GET',
    {},
    { withCredentials: true },
  ).then((res) => <CancellationCause[]>res.data.data)
}

export const cancelMembership = (cancellationCauseId: string) => {
  return fetch(
    `api/v1/member/membership/cancel`,
    'POST',
    { cancellationCauseId },
    { withCredentials: true },
  ).then((res) => res.data)
}

export const cancelHelp = () => {
  return fetch(
    `api/v1/member/helpservice/cancel`,
    'POST',
    { cancelHelpService: true },
    { withCredentials: true },
  ).then((res) => res.data)
}

export const getMemberArticles = (payload?: { page?: number; pageSize?: number }) => {
  return fetch(
    `api/v1/content/memberarticle/list`,
    'GET',
    { ...payload },
    { withCredentials: true },
  ).then((res) => <ArticleData[]>res.data.data.articles)
}

export const getPersonalizedMemberArticles = (payload?: { page?: number; pageSize?: number }) => {
  return fetch(
    `api/v1/content/memberarticle/personalized/list`,
    'GET',
    { ...payload },
    { withCredentials: true },
  ).then((res) => <ArticleData[]>res.data.data.articles)
}

export const getDikSessionArticles = (payload: { page?: number; pageSize?: number }) => {
  return fetch(
    `api/v1/content/dik-session/list`,
    'GET',
    { ...payload },
    { withCredentials: true },
  ).then((res) => <ArticleData[]>res.data.data.articles)
}

export const getMemberOfferArticles = (payload?: { page?: number; pageSize?: number }) => {
  return fetch(
    `api/v1/content/memberoffer/list`,
    'GET',
    { ...payload },
    { withCredentials: true },
  ).then((res) => <ArticleData[]>res.data.data.articles)
}

export const getSingleMemberOfferArticle = (path: string) => {
  return fetch(`api/v1/content/memberoffer/${path}`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data,
  )
}

export const getSingleMemberArticle = (path: string) => {
  return fetch(`api/v1/content/memberarticle/${path}`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data,
  )
}

export const getSingleDikSessionsArticle = (path: string) => {
  return fetch(`api/v1/content/dik-session/${path}`, 'GET', {}, { withCredentials: true })
    .then((res) => res.data)
    .catch((err) => err)
}

export const getInvoices = () => {
  return fetch(`api/v1/member/invoices`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data.data as Invoice[],
  )
}

export const getSacoToken = () => {
  return fetch(`api/v1/content/salarysearchtoken`, 'GET', {}, { withCredentials: true }).then(
    (res) => res.data,
  )
}
