import { get, first } from "lodash"
import { AuthState } from "store/auth/types"
import { navigate } from "@reach/router"

/**
 * Extracts the {placeholders} from the string and returns them in an array
 * @param desc {string}
 */
export const extractPlaceholderIdsFromDesc = (desc:string):string[] => {
    const matches = desc && desc.length ? desc.match(/{[a-zA-Z\-]*}/g) : ''


    return matches ? matches : []
}

/**
 * Split a string with {placeholders} to an array
 * @param {string}
 * @return {array}
 */
export const splitDescOnPlaceholders = (message:string) => {

    let data:string[] = []

    if (message) {

        let placeholders = message.match(/({)([a-zA-Z\-]*)(})/g)
        let startIndex = 0

        if (placeholders !== null) {
            placeholders.forEach( (match:string, index:number) => {

                let stop = message.indexOf(match) + match.length
                let extracted = message.substring(startIndex, stop)

                // If this is the last answered question we need to concatenate the part that appears after the {placeholder}
                if (placeholders && index === placeholders.length - 1) {
                    let lineComponents = message.split(new RegExp(match,"g"))
                    extracted = `${extracted} ${lineComponents[1]}` // When there's only one question answered or the very last one
                }

                data.push(extracted)
                startIndex = stop
            })
        }
    }

    return data
}

export const getSummaryBasedOnMembershipData = (desc:string, placeholders:string[], membershipData:{ [key: string]: any }) => {
    let summary = desc
    let valueName = ""

    for (let i=0; i < placeholders.length; ++i) {
        let placeholder = placeholders[i]
        let placeholderSplitted = placeholder.split(/({)([a-zA-Z\-]*)(})/g)

        if (typeof placeholderSplitted[3] === 'undefined') {
            return
        }

        let value = get(placeholderSplitted, '2')

        const valueName = (
            membershipData[value] && membershipData[value] !== undefined ? (
                membershipData[value].name
            ) : membershipData[value]
        )

        summary = summary.replace(placeholder, valueName)
    }

    return summary
}

export const sortArticlesByDate = (articles?: any[]) => {

    const sortedArticles = articles?.sort((a, b) => {
        if(!a.node || !b.node) { return 0 }
        return new Date(b.node.publishDate).getTime() - new Date(a.node.publishDate).getTime()
    })

    return sortedArticles
}