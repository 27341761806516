import React, { useEffect, ComponentProps } from "react";
import MyPages from "./mypages";
import { Router } from "@reach/router";
import { IntlProvider } from "react-intl";
import locale from "../../data/mypages.json";
import { flattenObject } from "../../utils/tools";
import Fakturor, { Invoice } from "./fakturor";
import Uppgifter from "./uppgifter";
import MittKort from "./mittKort";
import MinaFormaner from "./formaner";

type Props = {
  invoices: Invoice[];
  coachOptionsContent: ComponentProps<typeof MyPages>["coachOptionContent"];
  invoicePaymentDetails?: string;
  default?: true;
  path?: string;
};

//How does this even do anything
const flattedLocale = flattenObject(locale);

const MypagesApp = (props: Props) => {
  return (
    <IntlProvider locale="en" messages={flattedLocale}>
      <Router>
        <MyPages
          default
          path="mina-sidor"
          coachOptionContent={props.coachOptionsContent}
        />
        <Fakturor
          path="mina-sidor/fakturor"
          invoicePaymentDetails={props.invoicePaymentDetails}
          invoices={props.invoices}
        />
        <Uppgifter path="mina-sidor/uppgifter" />
        <MinaFormaner path="mina-sidor/formaner" />
        <MittKort path="mina-sidor/mitt-kort" />
      </Router>
    </IntlProvider>
  );
};

export default MypagesApp;
