import React from "react";
import { ArticleState } from "../mypages";
import { Link } from "@reach/router";
import imagesLoaded from "imagesloaded";
import LoaderEllipsis from "components/loader/LoaderEllipsis";
import Button from "components/button/Button";
import { injectIntl, InjectedIntl } from "react-intl";
import Article, { ArticleData } from "components/Article/Article";
// import Isotope from "isotope-layout"

let Isotope: any;
if (typeof window !== "undefined") {
  Isotope = require("isotope-layout");
}

interface Props {
  intl: InjectedIntl;
  articleState: ArticleState;
  rowCount?: number;
  loadMore?: () => void;
  layoutMode?: string;
}

interface State {
  isotope?: Isotope;
  hasLoadedMore: boolean;
}

const SLUG_PREFIX = "";
const CONTAINER_SELECTOR = ".js-articleContainer";
const ITEM_SELECTOR = ".js-article";
const ITEM_CLASS = "js-article";

class ArticleListing extends React.Component<Props, State> {
  static defaultProps = {
    rowCount: 2
  };

  state: State = {
    isotope: undefined,
    hasLoadedMore: false
  };

  componentDidUpdate(prevProps: Props) {
    if (prevProps === this.props) {
      return;
    }

    const { data } = this.props.articleState;
    const { data: prevData } = prevProps.articleState;
    const { isotope } = this.state;
    let articleDiff: ArticleData[] = [];

    if (!isotope && data) {
      return this.initIsotope();
    }

    if (data && prevData) {
      const dataDiff = data.filter(article => {
        if (!prevData) {
          return null;
        }
        return !prevData.includes(article);
      });

      dataDiff.forEach(diff => {
        articleDiff.push(diff);
      });
    }

    if (articleDiff.length && isotope) {
      imagesLoaded(CONTAINER_SELECTOR, () => {
        isotope.reloadItems();
        isotope.arrange({});
      });
    }
  }

  initIsotope = () => {
    this.setState(
      {
        isotope: new Isotope(CONTAINER_SELECTOR, {
          itemSelector: ITEM_SELECTOR,
          layoutMode: this.props.layoutMode ? this.props.layoutMode : "masonry"
        })
      },
      () => {
        imagesLoaded(CONTAINER_SELECTOR, () => {
          if (!this.state.isotope) {
            return;
          }
          this.state.isotope.arrange({});
        });
      }
    );
  };

  renderArticle = (article: ArticleData, i?: number) => {
    const { rowCount } = this.props;
    const sizeClass = `u-lg-size1of${rowCount}`;
    const url = `${SLUG_PREFIX}/${article.slug}`;

    return (
      <li
        key={i ? i : article.slug}
        className={`Grid-cell u-sizeFull ${sizeClass} ${ITEM_CLASS}`}
      >
        <Article {...article} url={url} />
      </li>
    );
  };

  loadMore = () => {
    this.setState({ hasLoadedMore: true });
    this.props.loadMore && this.props.loadMore();
  };

  render() {
    const { articleState, intl } = this.props;
    const { loadMore } = this;
    const { formatMessage } = intl;
    const { data, isLoadingMore, isLoadingInit, endOfArticles } = articleState;
    const renderEmptyState = () => (
      <p className="u-textCenter u-marginVxlg u-colorTextLight">
        {formatMessage({ id: "articleListing.noAvailableArticles" })}
      </p>
    );
    const renderEndOfListState = () => (
      this.state.hasLoadedMore ? <p className="u-textCenter u-marginVxlg u-colorTextLight">
        {formatMessage({ id: "articleListing.noMoreArticles" })}
      </p> :
      <div className="u-marginBxlg" />
    );

    const renderLoadMore = () => {
      if (endOfArticles) {
        return renderEndOfListState();
      }
      if (!loadMore || this.props.articleState.data == null) {
        return ;
      }

      return (
        <Button
          isLoading={isLoadingMore}
          onClick={loadMore}
          loaderColor="dark"
          className="button button-border blue u-marginVxlg myPage-article-button u-posCenter"
        >
          Ladda fler
        </Button>
      );
    };

    const renderLoader = () => {
      if (isLoadingInit) {
        return (
          <div className="u-size1of1 u-textCenter">
            <LoaderEllipsis color="dark" />
          </div>
        );
      }
    };

    if (data && !data.length) {
      return renderEmptyState();
    }

    return (
      <React.Fragment>
        {data && data.length && (
          <ul className="Grid Grid--gutterA js-articleContainer">
            {data.map(this.renderArticle)}
          </ul>
        )}

        {renderLoader()}
        {renderLoadMore()}
      </React.Fragment>
    );
  }
}

export default injectIntl(ArticleListing);
