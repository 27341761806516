import React from "react";
import ReactDOM from "react-dom";
import { get } from "lodash";
import ArrowDown from "../../../../images/Icons/arrow_drop_down.svg";
import ArrowUp from "../../../../images/Icons/arrow_drop_up.svg";
import { AuthState } from "store/auth/types";
import { ApplicationState } from "store";
import { connect } from "react-redux";
import { CONTACT_DIK_DIREKT_LINK } from "../../../../config/constants";

interface Props {
  unbook: (unbook: {}) => void;
  postBook: (postBook: {}) => void;
  courseId: string;
  reservationId: string;
  coachName: string;
  name: string;
  location: string;
  status: string;
  startDate: string;
  endDate: string;
  booked: boolean;
  index: number;
  activeTab: number;
}

interface ConnectProps {
  auth: AuthState;
}

class Panel extends React.Component<Props & ConnectProps> {

  panelElement: HTMLElement | null = null;
  
  state = {
    height: 0
  };

  componentDidMount() {
    window.setTimeout(() => {
      if(this.panelElement != null){
        const height = this.panelElement.querySelector(".panel__inner")?.scrollHeight;
        height && this.setState({
          height
        });
      }
    }, 333);
  }

  render() {
    const {
      startDate,
      endDate,
      activeTab,
      index,
      activateTab,
      booked,
      name,
      coachName,
      location,
      courseId,
      reservationId
    } = this.props;

    const { height } = this.state;
    const isActive = activeTab === index;
    const innerStyle = {
      height: `${isActive ? height + 24 : 0}px` // Add 24 px to fix margin overflow
    };

    let date = new Date(startDate);
    let optionsDate = { weekday: "short", month: "short", day: "numeric" };

    const role =
      this.props.auth.status === "LOGGED_IN" ? this.props.auth.role : null;

    return (
      <div className="panel" data-booked={booked} ref={ref => this.panelElement = ref}>
        <div
          className="myPages-fakturor-obetald-panel"
          aria-expanded={isActive}
          onClick={activateTab}
        >
          <div
            className={`calendar-panel calendar-panel-${
              booked ? "booked" : "nonBooked"
            }`}
          >
            <div>
              <p
                className={`calendar-panel-${
                  booked ? "booked" : "nonBooked"
                }-label u-marginAz`}
              >
                {date.toLocaleDateString("sv-SE", optionsDate)}
              </p>
              <p className="u-marginAz">{name}</p>
            </div>
            {isActive ? (
              <ArrowUp className={booked ? "u-fillWhite" : ""} />
            ) : (
              <ArrowDown className={booked ? "u-fillWhite" : ""} />
            )}
          </div>
        </div>
        <div
          className="panel__inner Grid"
          style={innerStyle}
          aria-hidden={!isActive}
        >
          <div className="u-marginVmd" key={index}>
            <p className="panel__content_label">{location}</p>
            <p className="panel__content">
              {name} {get(coachName, "") ? `med ${coachName}` : null}
            </p>
            {role === "Prio" || booked ? (
              <button
                onClick={() =>
                  booked
                    ? this.props.unbook(this.props)
                    : this.props.postBook(this.props)
                }
                className="button button--normal blue u-marginLsm u-marginTxsm"
              >
                {booked ? "Avboka" : "Boka"}
              </button>
            ) : (
              <>
                <p className="panel__content u-marginTsm">
                  Vill du boka in dig på denna aktivitet?
                </p>
                <p className="panel__content u-marginTsm u-textSmall">
                  Kontakta kundtjänst på{" "}
                  <a href={CONTACT_DIK_DIREKT_LINK} className="Link">
                    DIK Direkt
                  </a>{" "}
                  för att uppgradera ditt medlemskap.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(({ auth }: ApplicationState) => ({ auth }))(Panel);
