import React, { useContext, useMemo } from 'react'
import CoachOptionContext from '../CoachOptionContext'
import ArrowDown from '../../../images/Icons/arrow_drop_down.svg'
import { Spot } from 'apiConfig/apiMethods'

interface Props {
  spots: Spot[]
  selectSpot(spot: Spot): void
}

const Timeslots = (props: Props) => {
  const coachOptionContext = useContext(CoachOptionContext)
  const coachOptions = useMemo(() => coachOptionContext[name], [coachOptionContext])
  return (
    <div className="u-textCenter">
      {props.spots.map((value, index) => {
        let time = new Date(value.startDate)
        let optionsTime = { hour: '2-digit', minute: '2-digit' }

        return (
          <button
            className="button button-rounded u-marginBsm u-marginHxsm"
            onClick={() => props.selectSpot(value)}
            key={index}
          >
            {time.toLocaleTimeString('sv-SE', optionsTime)}
          </button>
        )
      })}
    </div>
  )
}

export default Timeslots
