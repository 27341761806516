import React from "react";
import chatBox from "../../images/Chat-box.svg";
import "./StaticCoachBanner.less";

export default function StaticCoachBanner(props: {
  heading?: string;
  preamble?: string;
  links: { text: string; url: string }[];
}) {
  return (
    <div className="u-xsm-size1of1 u-md-size2of3">
      <div className="myPages-banners u-paddingLz">
        <div className="SpotsBanner" onMouseDown={(e) => {}}>
          <img className="SpotsBanner-icon" src={chatBox} />

          <div className="SpotsBanner-content">
            <h5>{props.heading}</h5>
            {props.preamble && (
              <p style={{ whiteSpace: "pre-wrap" }}>{props.preamble}</p>
            )}
          </div>

          <div className="SpotsBanner-buttons">
            {props.links.map((link) => (
              <a
                key={link.text}
                href={link.url}
                target="_blank"
                className="button button--normal blue"
              >
                {link.text}
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
