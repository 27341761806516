import React, { createContext, PropsWithChildren } from 'react'

export type CoachData = {
  sortOrder: number
  preamble: string
  options: string[]
}

const staticContext: { [CoachCode: string]: CoachData } = {
  karriärcoach: {
    sortOrder: 0,
    preamble:
      'Vi hjälper dig att hitta verktygen och insikterna, så att du ska kunna nå dina mål och bli ditt bästa jag.',
    options: [
      'Konkreta frågan – du, en coach och 15 minuter',
      'Dags för förändring – ett utforskande samtal med din coach',
      'Vad du kan och vad du vill – coachen hjälper dig reda ut',
      'Första steget till en intervju – ett bra CV. Vi hjälper dig på vägen',
      'Stärk din Linkedin-profil tillsammans med en coach',
      'Dags för intervju? Förbered dig med hjälp av en coach',
    ],
  },
  lönecoach: {
    sortOrder: 1,
    preamble: 'Så maxar du lönen!',
    options: [
      'Dags för lönesamtal? Vässa argumenten med hjälp av en coach',
      'Jag har nytt jobb på gång, vad ska jag ange för löneanspråk?',
      'Var lönestrategisk. Sätt en långsiktig plan med din coach',
    ],
  },
  chefsrådgivning: {
    sortOrder: 3,
    preamble: 'Så maxar du lönen!',
    options: [
      'Dags för checf? Vässa argumenten med hjälp av en coach',
      'Jag har nytt jobb på gång, vad ska jag ange för löneanspråk?',
      'Var lönestrategisk. Sätt en långsiktig plan med din coach',
    ],
  },
  egenföretagarcoach: {
    sortOrder: 2,
    preamble: 'Starta och driva eget företag? Vi ger dig stöd',
    options: [
      'Vad behöver jag tänka på när jag startar eget?',
      'Vill du utveckla ditt företag? Coachen stöttar dig',
      'Att ta betalt för mina tjänster. Hur ska jag tänka?',
    ],
  },
}

const CoachOptionContext = createContext(staticContext)

export default CoachOptionContext

export function CoachOptionProvider({ children }: PropsWithChildren<{}>) {
  return <CoachOptionContext.Provider value={staticContext}>{children}</CoachOptionContext.Provider>
}
