import React from 'react'
import './calendar.less'
import Accordion from './Accordion/Accordion'
import { getAllEvents, getAllBookedEvents } from 'apiConfig/apiMethods'
import LoaderEllipsis from 'components/loader/LoaderEllipsis'

interface CoursesProps {
  courseId: string
  coachName: string
  name: string
  location: string
  status: string
  startDate: string
  endDate: string
  seatsLeft: number
}

interface Props extends CoursesProps {
  unbook: (unbook: {}) => void
  postBook: (postBook: {}) => void
  fetchEvents: () => void
  year: number
  reload: number
}

interface BookedProps extends CoursesProps {
  reservationId: string
}

interface State {
  selectedMonth: number
  selectedYear: number
  months: { month: string; events: Array<CoursesProps> }[]
  bookedMonths: { month: string; events: Array<BookedProps> }[]
  loaded: boolean
  loadedBooked: boolean
}

class Calendar extends React.Component<Props, State> {
  state = {
    selectedMonth: new Date().getMonth(),
    selectedYear: new Date().getFullYear(),
    months: [
      { month: 'Jan', events: [] },
      { month: 'Feb', events: [] },
      { month: 'Mar', events: [] },
      { month: 'Apr', events: [] },
      { month: 'Maj', events: [] },
      { month: 'Jun', events: [] },
      { month: 'Jul', events: [] },
      { month: 'Aug', events: [] },
      { month: 'Sep', events: [] },
      { month: 'Okt', events: [] },
      { month: 'Nov', events: [] },
      { month: 'Dec', events: [] },
    ],
    bookedMonths: [
      { month: 'Jan', events: [] },
      { month: 'Feb', events: [] },
      { month: 'Mar', events: [] },
      { month: 'Apr', events: [] },
      { month: 'Maj', events: [] },
      { month: 'Jun', events: [] },
      { month: 'Jul', events: [] },
      { month: 'Aug', events: [] },
      { month: 'Sep', events: [] },
      { month: 'Okt', events: [] },
      { month: 'Nov', events: [] },
      { month: 'Dec', events: [] },
    ],
    loaded: false,
    loadedBooked: false,
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps === this.props) {
      return
    }

    if (prevProps.year !== this.props.year) {
      // Select first month when changing year
      if (this.props.year !== new Date().getFullYear()) {
        this.setState({ selectedYear: this.props.year })
        this.setState({ selectedMonth: 0 })
      } else {
        this.state.selectedYear = this.props.year
        this.state.selectedMonth = new Date().getMonth()
      }
    }

    if (prevProps.year !== this.props.year || prevProps.reload !== this.props.reload) {
      this.setState({
        months: [
          { month: 'Jan', events: [] },
          { month: 'Feb', events: [] },
          { month: 'Mar', events: [] },
          { month: 'Apr', events: [] },
          { month: 'Maj', events: [] },
          { month: 'Jun', events: [] },
          { month: 'Jul', events: [] },
          { month: 'Aug', events: [] },
          { month: 'Sep', events: [] },
          { month: 'Okt', events: [] },
          { month: 'Nov', events: [] },
          { month: 'Dec', events: [] },
        ],
      })

      this.setState({
        bookedMonths: [
          { month: 'Jan', events: [] },
          { month: 'Feb', events: [] },
          { month: 'Mar', events: [] },
          { month: 'Apr', events: [] },
          { month: 'Maj', events: [] },
          { month: 'Jun', events: [] },
          { month: 'Jul', events: [] },
          { month: 'Aug', events: [] },
          { month: 'Sep', events: [] },
          { month: 'Okt', events: [] },
          { month: 'Nov', events: [] },
          { month: 'Dec', events: [] },
        ],
      })

      this.fetchEvents()
    }
  }

  componentDidMount() {
    this.fetchEvents()
  }

  fetchEvents = () => {
    this.setState({ loaded: false })
    this.setState({ loadedBooked: false })
    getAllEvents().then((data) => {
      let newMonths = this.state.months
      const monthsmap = data.map((event: CoursesProps, index: number) => {
        let num = new Date(event.startDate).getMonth()

        if (
          new Date(event.startDate).getFullYear() === this.props.year &&
          new Date(event.startDate) > new Date()
        ) {
          newMonths[num].events.push(event)
        }
      })
      setTimeout(() => {
        this.setState({ loaded: true })
        this.setState({ months: newMonths })
      }, 1000)
    })
    getAllBookedEvents().then((data) => {
      let newMonths = this.state.bookedMonths
      const monthsmap = data.map((event: CoursesProps, index: number) => {
        let num = new Date(event.startDate).getMonth()

        if (
          new Date(event.startDate).getFullYear() === this.props.year &&
          new Date(event.startDate) > new Date()
        ) {
          newMonths[num].events.push(event)
        }
      })
      setTimeout(() => {
        this.setState({ loadedBooked: true })
        this.setState({ bookedMonths: newMonths })
      }, 1000)
    })
  }

  public render() {
    const { selectedMonth, selectedYear } = this.state
    return (
      <>
        <div className="calendar-view">
          {this.state.months.map((month, index: number) => {
            let now = new Date()
            let newKey = `${index}${this.props.year}`
            const enabled = now.getFullYear() < this.props.year || index >= now.getMonth()
            return (
              <div
                key={newKey}
                className={`calendar-block Grid-cell--fit ${enabled ? '' : 'disabled'} ${
                  selectedMonth === index && selectedYear === this.props.year ? 'active' : ''
                }`}
                onClick={() =>
                  enabled
                    ? this.setState({ selectedMonth: index, selectedYear: this.props.year })
                    : null
                }
              >
                <p className="calendar-block-month">{month.month}</p>
                <div className="calendar-block-month-dots u-textTruncate">
                  <div>
                    {this.state.bookedMonths[index].events.map((dots, index) => (
                      <span key={index} className="blue" />
                    ))}
                  </div>
                  <div style={{ marginLeft: '4px' }}>
                    {month.events.map((dots, index) => (
                      <span key={index} className="beige" />
                    ))}
                  </div>
                </div>
              </div>
            )
          })}
        </div>

        <p className="myPage-label u-marginBsm u-marginTxlg">Bokade aktiviteter</p>
        {this.state.bookedMonths[selectedMonth].events.length > 0 ? (
          this.state.loadedBooked ? (
            <Accordion
              booked
              panels={this.state.bookedMonths[selectedMonth].events}
              unbook={this.props.unbook}
            />
          ) : (
            <LoaderEllipsis color="dark" />
          )
        ) : this.state.loadedBooked ? (
          <i>Inga bokade aktiviteter</i>
        ) : (
          <LoaderEllipsis color="dark" />
        )}
        <p className="myPage-label u-marginBsm u-marginTxlg">Andra aktiviteter</p>
        {this.state.months[selectedMonth].events.length > 0 ? (
          this.state.loaded ? (
            <Accordion
              // Filter out booked events from list
              panels={this.state.months[selectedMonth].events.filter(
                (event) =>
                  this.state.bookedMonths[selectedMonth].events
                    .map((event) => event.courseId)
                    .indexOf(event.courseId) === -1,
              )}
              postBook={this.props.postBook}
            />
          ) : (
            <LoaderEllipsis color="dark" />
          )
        ) : this.state.loaded ? (
          <i>Inga aktiviteter för denna månad</i>
        ) : (
          <LoaderEllipsis color="dark" />
        )}
      </>
    )
  }
}

export default Calendar
