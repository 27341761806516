//** Simple validation since we only have one field at a time on screen

import { swedishPersonalNumber } from "../../../utils/validation";

export interface Validate {
  (type: string, value: string): boolean;
}

const validate: Validate = (type: string, value: string): boolean => {
  let isValid = false;

  switch (type) {
    case "email":
      isValid = /\S+@\S+\.\S+/.test(value);
      break;
    case "personalNumber":
      isValid = swedishPersonalNumber(value);
      break;
    case "phone":
      isValid = /(\+?46|0)7\d{8}/.test(value);
      break;
    case "alphaOnly":
      isValid = /([A-Za-z\.,-_])/g.test(value);
      break;
    case "date":
      isValid = /[\d,._\s-]{6,10}/.test(value);
      break;
    case "alphabetAndMostCommonSpecialChars":
      isValid = /[A-Za-z,._\s-]{2,200}/.test(value);
      break;
    case "almostAnythingGoes":
      isValid = value.length >= 2;
      break;
    case "lon":
      isValid = /[\d,._\s-]{5,7}/.test(value);
      break;
    case "inkomst":
      isValid = /^[0-9]*$/.test(value);
      break;
    default:
      isValid = true;
  }

  return isValid;
};

export default validate;
